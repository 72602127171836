// Form types
export const CLEAR_FORM_STORE_ALL = 'CLEAR_FORM_STORE_ALL';
export const CLEAR_FORM_STORE_SOME = 'CLEAR_FORM_STORE_SOME';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const GET_FORM_STORE = 'GET_FORM_STORE';
export const SAVE_FORM = 'SAVE_FORM';
export const UPDATE_FORM_STORE = 'UPDATE_FORM_STORE';

// Geo types
export const CLEAR_GEO_STORE = 'CLEAR_GEO_STORE';
export const GET_GEO_STORE = 'GET_GEO_STORE';
export const SAVE_GEO = 'SAVE_GEO';
