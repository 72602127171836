import React from 'react';
import { uid } from 'react-uid';
import PropTypes from 'prop-types';
import times from 'lodash/times';
import { Line } from 'rc-progress';
import styled from 'styled-components';

import media from '../constants';

/**
 * Styling of the component.
 *
 * @type {Object}
 */
const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto 40px;
  padding: 0;
  position: relative;
  width: 100%;
  order: -1;

  ${media.tablet`
    height: 60px;
  `};
`;

const StyledLineContainer = styled.div`
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 100%;
`;

const StyledLine = styled(Line)`
  height: 6px;
  max-width: 100%;
  padding: 0 20px;
  width: 100%;

  .rc-progress-line-path {
    stroke: ${props => props.theme.progressBar.backgroundColorActive};
  }
`;

const StyledLineDotContainer = styled.ol`
  align-items: space-between;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  left: 0;
  padding: 0 20px;
  position: absolute;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  list-style: none;
`;

const StyledLineDot = styled.div`
  align-items: center;
  background-color: ${props => props.theme.progressBar.backgroundColor};
  border: 2px solid ${props => props.theme.progressBar.borderColor};
  border-radius: 50%;
  color: ${props => props.theme.progressBar.color};
  display: flex;
  font-size: ${props => props.theme.progressBar.fontSize};
  font-weight: ${props => props.theme.progressBar.fontWeight};
  height: 30px;
  justify-content: center;
  line-height: 0;
  margin-bottom: 5px;
  width: 30px;

  &.active {
    background-color: ${props => props.theme.progressBar.backgroundColorActive};
    border-color: ${props => props.theme.progressBar.borderColorActive};
    color: ${props => props.theme.progressBar.colorActive};
  }
`;

const StyledLineStep = styled.li`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25%;
`;

const StyledName = styled.div`
  color: ${props => props.theme.progressBarNames.color};
  display: none;
  font-size: 16px;

  ${media.tablet`
    display: flex;
  `};

  &.active {
    color: ${props => props.theme.progressBarNames.colorActive};
  }
`;

const STEP_NAMES = [
  { name: 'Waar is het?' },
  { name: 'Waar gaat het om?' },
  { name: 'Mijn gegevens' },
  { name: 'Controle' },
];

const ProgressBar = ({ step, steps }) => (
  <StyledContainer>
    <StyledLineContainer>
      <StyledLine percent={((steps.indexOf(step) + 1) / steps.length) * 100} />
    </StyledLineContainer>
    <StyledLineDotContainer
      role="progressbar"
      aria-label="Stappen progressie"
      aria-valuemin="0"
      aria-valuemax={steps.length}
      aria-valuenow={steps.indexOf(step)}
      aria-valuetext={
        steps.indexOf(step) > -1 && STEP_NAMES[steps.indexOf(step)]
          ? STEP_NAMES[steps.indexOf(step)].name
          : ''
      }
    >
      {steps &&
        times(steps.length, i => (
          <StyledLineStep key={uid(i)} aria-current={steps.indexOf(step) === i ? true : null}>
            <StyledLineDot className={steps.indexOf(step) >= i ? 'active' : ''}>
              {i + 1}
            </StyledLineDot>
            <StyledName className={steps.indexOf(step) >= i ? 'active' : ''}>
              {STEP_NAMES[i].name}
            </StyledName>
          </StyledLineStep>
        ))}
    </StyledLineDotContainer>
  </StyledContainer>
);

ProgressBar.propTypes = {
  step: PropTypes.instanceOf(Object).isRequired,
  steps: PropTypes.instanceOf(Object).isRequired,
};

export default ProgressBar;
