import validator from 'validator';
import isEmpty from '../is-empty';

const validatePhoneNumbers = phone => {
  // const landline = /^(((0)[1-9]{2}[0-9][-]?[1-9][0-9]{5})|((\\+31|0|0031)[1-9][0-9][-]?[1-9][0-9]{6}))$/;
  // const mobile = /^(((\\+31|0|0031)6){1}[1-9]{1}[0-9]{7})$/i;
  const combinedRegex = /^(\+?[0-9]{2}|^\+?[0-9]{2}\(0\)|^\(\+?[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)$/;
  return combinedRegex.test(phone);
};

const validateStepThree = data => {
  const errors = {};

  // Set default values in case of empty
  data.name = !isEmpty(data.name) ? data.name : '';
  data.phoneNumber = !isEmpty(data.phoneNumber) ? data.phoneNumber : '';
  data.statusUpdates = !isEmpty(data.statusUpdates) ? data.statusUpdates : '';
  data.email = !isEmpty(data.email) ? data.email : '';

  // Error cases
  if (validator.isEmpty(data.name)) {
    errors.name = 'Het invullen van het veld Naam is verplicht.';
  }

  if (!validatePhoneNumbers(data.phoneNumber)) {
    errors.phoneNumber = 'Het door u ingevulde telefoonnummer is ongeldig.';
  }

  if (validator.isEmpty(data.phoneNumber)) {
    errors.phoneNumber = 'Het invullen van het veld Telefoonnummer is verplicht.';
  }

  if (validator.isEmpty(data.statusUpdates)) {
    errors.statusUpdates = 'Gelieve een keuze te maken.';
  }

  if (!validator.isEmpty(data.email) && !validator.isEmail(data.email)) {
    errors.email = `Het ingevuld e-mailadres is niet geldig.`;
  }

  if (data.statusUpdates === 'Ja' && validator.isEmpty(data.email)) {
    errors.email = `Het veld E-mail is verplicht.`;
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default validateStepThree;
