import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';

import { lansingerland } from './themes';
import App from './App';
import './fontAwesome';
import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

ReactDOM.render(
  <ThemeProvider theme={lansingerland}>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);
