import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';

import configureStore from './store/configureStore';
import DoneView from './containers/DoneView';
import WizardSteps from './containers/WizardSteps';
import NotFound from './containers/NotFound';
import Header from './components/Header';
import PermissionBar from './components/PermissionBar';
import AddToHomescreen from './components/AddToHomeScreen';

const store = configureStore;

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    height: 100%;
    background-color: ${props => props.theme.document.background};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }

  body {
    min-height: 100%;
    margin: 0;
    padding: 0;
    font-family: ${props => props.theme.document.fontFamily};
    font-size: ${props => props.theme.document.fontSize};
    font-weight: normal;
    font-style: normal;
    line-height: 1.5;
    color: ${props => props.theme.document.color};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* Remove default focus styles for mouse users if :focus-visible is supported */
  a:focus:not(:focus-visible)
  input:focus:not(:focus-visible),
  select:focus:not(:focus-visible),
  textarea:focus:not(:focus-visible),
  button:focus:not(:focus-visible) {
    outline: none;
  }

  input:focus-visible,
  select:focus-visible,
  textarea:focus-visible {
    box-shadow: 0 0 4px ${props => props.theme.input.focus};
    outline: none; 
  }

  a:focus-visible,
  input[type="radio"]:focus-visible,
  input[type="checkbox"]:focus-visible, 
  button:focus-visible {
    outline: 2px solid ${props => props.theme.input.focus};
  }

  /**
   * Animations
   */
  .forward-enter {
    transform: translateX(100%);
  }
  .forward-enter.forward-enter-active {
    transform: translateX(0);
    transition: transform 500ms ease-in-out;
  }
  .forward-exit {
    transform: translateX(0);
  }
  .forward-exit.forward-exit-active {
    transform: translateX(-100%);
    transition: transform 500ms ease-in-out;
  }

  .back-enter {
    transform: translateX(-100%);
  }
  .back-enter.back-enter-active {
    transform: translateX(0);
    transition: transform 500ms ease-in-out;
  }
  .back-exit {
    transform: translateX(0);
  }
  .back-exit.back-exit-active {
    transform: translateX(100%);
    transition: transform 500ms ease-in-out;
  }

  /**
   * Modal - Lock the scroll position by adding this class to the '<html>' element.
   */
  .u-lock-scroll {
    overflow: hidden !important;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const App = () => (
  <Provider store={store}>
    <StyledContainer>
      <GlobalStyle />
      <PermissionBar />
      <Header />
      <Router>
        <Switch>
          <Route path="/bedankt" component={DoneView} />
          <Route
            exact
            render={({ history, step, steps }) => (
              <WizardSteps history={history} step={step} steps={steps} />
            )}
          />
          <Route component={NotFound} />
        </Switch>
      </Router>
      <AddToHomescreen />
    </StyledContainer>
  </Provider>
);

export default App;
