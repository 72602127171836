import categories from './initialStates/categories';
import form from './initialStates/form';
import geo from './initialStates/geo';

const initialState = {
  categories,
  form,
  geo,
};

export default initialState;
