import logo from './logo.png'
import appStyles from './appStyles'
import colors from './colors'
import fonts from './fonts'
import metrics from './metrics'

import infoIcon from './info-icon.png'

const yard = {
  /**
   * Component styles
   */
  document: {
    logo,
    logoHeightSmall: '50px',
    logoHeightTablet: '66px',
    background: colors.lightGray,
    color: colors.black,
    fontFamily: '"Roboto", sans-serif',
    fontSize: '18px'
  },
  introText: {
    fontSize: '20px',
    fontWeight: fonts.weight.regular,
    margin: `0 auto ${metrics.spacing.xxl}`,
    maxWidth: '600px',
    padding: `0 ${metrics.spacing.xl}`
  },
  requiredAsteriks: {
    color: colors.red
  },
  errorMessageFeedback: {
    margin: '5px 0 0 15px',
    color: colors.red,
    fontSize: fonts.size.small
  },
  errorMessageApi: {
    backgroundColor: colors.lightRed,
    color: colors.red,
    fontSize: fonts.size.default,
    marginBottom: metrics.spacing.xxl
  },
  header: {
    background: colors.white,
    boxShadow: appStyles.boxShadow,
    marginBottom: '40px',
    padding: `${metrics.spacing.md} ${metrics.spacing.xl}`
  },
  navigation: {
    padding: `${metrics.spacing.xxl} 0`
  },
  button: {},
  buttonSolid: {
    backgroundColor: colors.blue,
    borderColor: colors.blue,
    borderRadius: '25px',
    color: colors.white,
    fontSize: '18px',
    padding: '10px 20px'
  },
  buttonSolidHover: {
    backgroundColor: 'transparent',
    borderColor: colors.blue
  },
  link: {
    color: colors.blue
  },
  inputWrapper: {
    margin: `0 0 ${metrics.spacing.lg} 0`
  },
  input: {
    background: colors.white,
    borderColor: colors.gray,
    borderError: colors.red,
    borderRadius: '50px',
    fontFamily: fonts.type.roboto,
    fontSize: '16px',
    fontWeight: fonts.weight.regular,
    height: '50px',
    padding: metrics.spacing.lg,
    width: '100%'
  },
  inputDescription: {
    color: colors.darkGray,
    margin: '5px 0 0 15px'
  },
  select: {
    background: colors.white,
    borderRadius: '50px',
    border: `solid 1px ${colors.gray}`,
    borderError: `solid 1px ${colors.red}`,
    color: colors.black,
    fontSize: '16px',
    height: '50px',
    lineHeight: '1.5',
    margin: '0',
    padding: '0 18px',
    width: '100%'
  },
  textarea: {
    background: colors.white,
    border: colors.gray,
    borderError: colors.red,
    borderRadius: '15px',
    color: colors.black,
    fontFamily: fonts.type.roboto,
    fontSize: '16px',
    fontWeight: fonts.weight.regular,
    padding: metrics.spacing.lg
  },
  dropzone: {
    activeBackground: 'rgba(92, 106, 196, 0.04)',
    activeBorderColor: '#5C6AC4',
    alignItems: 'center',
    background: '#f9f8f7',
    border: '2px dashed #babbbb',
    borderRadius: '3px',
    cursor: 'pointer',
    display: 'flex',
    height: '250px',
    fontSize: '18px',
    justifyContent: 'center',
    margin: '0 auto 1rem',
    padding: metrics.spacing.lg,
    width: '100%'
  },
  dropzoneActive: {
    border: colors.blue
  },
  dropzoneButton: {
    background: colors.white,
    backgroundHover: colors.gray,
    borderColor: colors.gray,
    borderRadius: '50px',
    color: colors.black,
    fontSize: '16px',
    height: '50px',
    padding: '8px 15px'
  },
  dropzoneRemoveButton: {
    color: colors.black,
    fontSize: '20px',
    height: '20px',
    width: '20px'
  },
  modalClose: {
    color: colors.blue,
    fontSize: '18px',
    fontWeight: 'bold',
    infoIcon
  },
  modalTrigger: {
    color: colors.blue,
    fontSize: '18px',
    fontWeight: 'bold',
    infoIcon
  },
  progressBar: {
    backgroundColor: colors.white,
    backgroundColorActive: colors.blue,
    borderColor: colors.gray,
    borderColorActive: colors.blue,
    color: colors.darkGray,
    colorActive: colors.white,
    fontSize: '15px',
    fontWeight: 'bold'
  },
  progressBarNames: {
    color: colors.darkGray,
    colorActive: colors.blue
  },
  permissionBar: {
    backgroundColor: colors.blue,
    border: `2px solid ${colors.white}`,
    color: colors.white,
    padding: '25px',
    margin: `0 0 ${metrics.spacing.lg}`
  },
  permissionBarButton: {
    backgroundColor: 'transparent',
    backgroundColorHover: colors.white,
    colorHover: colors.blue,
    border: `1px solid ${colors.white}`,
    borderRadius: '5px',
    color: colors.white,
    padding: '15px 20px'
  },
  addToHomeScreen: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.gray}`,
    borderRadius: '5px',
    bottom: '20px',
    color: colors.black,
    minHeight: '70px',
    padding: '10px 30px 10px 10px',
    left: '10px',
    right: '10px'
  },
  addToHomeScreenClose: {
    backgroundColor: colors.gray
  },
  /**
   * Step specific styles
   */
  stepDonePhoneNumber: {
    color: colors.blue,
    fontSize: '30px',
    marginBottom: '40px'
  },
  stepVerifySections: {
    borderBottom: `2px solid ${colors.gray}`,
    margin: `0 0 ${metrics.spacing.lg}`,
    paddingBottom: metrics.spacing.lg
  },
  stepVerifyParagraph: {
    margin: `0`,
    fontSize: '16px'
  }
}

export default yard
