import React, { Component, Fragment } from 'react';
import { uid } from 'react-uid';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InputWrapper from '../InputWrapper';

/**
 * Styling of the component.
 *
 * @type {Object}
 */
const StyledInner = styled.div`
  align-items: ${props => props.theme.dropzone.alignItems};
  background: ${props => props.theme.dropzone.background};
  border: ${props => props.theme.dropzone.border};
  border-radius: ${props => props.theme.dropzone.borderRadius};
  cursor: ${props => props.theme.dropzone.cursor};
  display: ${props => props.theme.dropzone.display};
  height: ${props => props.theme.dropzone.height};
  font-size: ${props => props.theme.dropzone.fontSize};
  flex-direction: column;
  justify-content: ${props => props.theme.dropzone.justifyContent};
  text-align: center;
  padding: ${props => props.theme.dropzone.padding};
  width: ${props => props.theme.dropzone.width};

  &:hover: {
    background-color: red;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }

  &.is-active,
  &:active,
  &:focus {
    outline: 2px solid ${props => props.theme.input.focus};
    border-color: ${props => props.theme.dropzoneActive.border};
  }
`;

const StyledButton = styled.div`
  align-items: center;
  background-color: ${props => props.theme.dropzoneButton.background};
  border-radius: ${props => props.theme.dropzoneButton.borderRadius};
  border: 1px solid ${props => props.theme.dropzoneButton.borderColor};
  color: ${props => props.theme.dropzoneButton.color};
  cursor: pointer;
  display: flex;
  font-size: ${props => props.theme.dropzoneButton.fontSize};
  height: ${props => props.theme.dropzoneButton.height};
  justify-content: center;
  padding: ${props => props.theme.dropzoneButton.padding};

  &:hover {
    background-color: ${props => props.theme.dropzoneButton.backgroundHover};
  }
`;

const StyledButtonText = styled.div`
  margin-left: 10px;
`;

const StyledThumbs = styled.aside`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const StyledThumbsItem = styled.div`
  background-color: transparent;
  padding: 0 30px 0 0;
`;

const StyledThumbsImgContainer = styled.div`
  align-items: center;
  border: 1px solid #000;
  border-radius: 2px;
  display: flex;
  height: 75px;
  justify-content: center;
  position: relative;
  width: 100px;
`;

const StyledThumbsItemImg = styled.img`
  display: block;
  height: 100%;
  max-height: 75px;
  max-width: 100px;
  width: auto;
`;

const StyledThumbsItemRemove = styled.button`
  background: transparent;
  border: none;
  color: ${props => props.theme.dropzoneRemoveButton.color};
  cursor: pointer;
  font-size: ${props => props.theme.dropzoneRemoveButton.fontSize};
  height: ${props => props.theme.dropzoneRemoveButton.height};
  line-height: 1;
  padding: 0;
  position: absolute;
  top: 0;
  right: -25px;
  width: ${props => props.theme.dropzoneRemoveButton.width};
`;

const StyledThumbsItemText = styled.p`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
`;

class Upload extends Component {
  constructor(props: {}) {
    super(props);

    const { data } = this.props;

    this.state = {
      files: data,
    };

    this.onDrop = this.onDrop.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { onChange } = this.props;
    const { files } = this.state;

    if (prevState.files !== files) {
      onChange({ target: { name: 'uploads', value: files } });
    }
  }

  // Cannot unmount and revoke object url because the user can go back to previous steps
  // The blob needs to stay an image at this point. Keeping this code here for reference.
  // componentWillUnmount() {
  //   const { files } = this.state;

  //   // Make sure to revoke the data uris to avoid memory leaks
  //   files.forEach(file => URL.revokeObjectURL(file.preview));
  // }

  onDrop = droppedFiles => {
    const { files } = this.state;
    let newFiles = droppedFiles;

    /* eslint-disable */
    for (let i = 0; i < newFiles.length; i++) {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = e => {
        newFiles[i].url = e.target.result;
      };
      reader.readAsDataURL(newFiles[i]);
      newFiles[i].preview = URL.createObjectURL(newFiles[i]);
    }
    /* eslint-enable */

    newFiles = [...newFiles];

    this.setState({ files: files.concat(newFiles) });
  };

  onRemove = index => {
    const { onChange } = this.props;

    let files;

    this.setState(state => {
      files = state.files.slice(0);
      files.splice(index, 1);
      return { files };
    }, onChange({ target: { name: 'uploads', value: files } }));
  };

  render() {
    const { files } = this.state;
    const { buttonText } = this.props;

    const thumbs = files.map((file, index) => (
      <StyledThumbsItem key={uid(file)}>
        <StyledThumbsImgContainer>
          <StyledThumbsItemImg src={file.preview} />
          <StyledThumbsItemRemove
            aria-label="Afbeeldingen verwijderen"
            onClick={() => this.onRemove(index)}
          >
            <FontAwesomeIcon icon="times-circle" />
          </StyledThumbsItemRemove>
        </StyledThumbsImgContainer>
        <StyledThumbsItemText>{file.name}</StyledThumbsItemText>
      </StyledThumbsItem>
    ));

    return (
      <Fragment>
        <InputWrapper>
          <Dropzone accept="image/jpeg, image/png" multiple onDrop={this.onDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <StyledInner
                {...getRootProps()}
                className={isDragActive ? 'is-active' : 'is-not-active'}
              >
                <input {...getInputProps()}  aria-label={buttonText} />
                <StyledButton>
                  {isDragActive ? (
                    <p>Sleep de foto naar dit veld</p>
                  ) : (
                    <Fragment>
                      <FontAwesomeIcon icon="camera" />
                      <StyledButtonText>{buttonText}</StyledButtonText>
                    </Fragment>
                  )}
                </StyledButton>
              </StyledInner>
            )}
          </Dropzone>
        </InputWrapper>
        <InputWrapper>
          <StyledThumbs>{thumbs}</StyledThumbs>
        </InputWrapper>
      </Fragment>
    );
  }
}

Upload.propTypes = {
  buttonText: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Array),
  onChange: PropTypes.func.isRequired,
};

Upload.defaultProps = {
  data: [],
};

export default Upload;
