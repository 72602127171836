import React from 'react';
import styled, { withTheme } from 'styled-components';

import config from '../config';
import media from '../constants';
import Modal from './common/modal/Modal';

import locationPin from '../img/pin-red.svg';

/**
 * Styling of the component.
 *
 * @type {Object}
 */
const StyledHeader = styled.div`
  align-items: center;
  background: ${props => props.theme.header.background};
  box-shadow: ${props => props.theme.header.boxShadow};
  display: flex;
  padding: ${props => props.theme.header.padding};
  position: relative;
  margin-bottom: ${props => props.theme.header.marginBottom};
`;

const StyledLink = styled.a`
  display: flex;
  height: 100%;
  width: 100%;
`;

const StyledLogo = styled.img`
  height: 100%;
  height: ${props => props.theme.document.logoHeightSmall};
  width: auto;

  ${media.tablet`
    height: ${props => props.theme.document.logoHeightTablet};
  `};
`;

const StyledHeading = styled.h2`
  font-size: 22px;
  font-weight: 300;
  margin: 0 0 10px;
`;

const StyledParagraph = styled.p`
  font-size: 16px;
  margin: 0 0 20px;

  .geo-marker {
    background-image: url(${locationPin});
    background-size: 14px;
    display: inline-block;
    height: 29px;
    width: 20px;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

const Header = ({ theme }) => {
  const modalContent = (
    <div>
      <StyledHeading>Welkom bij BuitenOpOrde!</StyledHeading>
      <StyledParagraph>
        Is er iets kapot, vies of niet in orde in de openbare ruimte? Geef het aan ons door. Vul de
        velden in van het formulier, voeg eventueel uw foto(‘s) toe en verzend de melding. De
        gemeente neemt uw melding in behandeling. Afhankelijk van uw voorkeur houden wij u per
        e-mail op de hoogte.
      </StyledParagraph>
      <StyledHeading>Gebruik van de app</StyledHeading>
      <StyledParagraph>
        Deze app is een onderdeel van www.lansingerland.nl. Bezoekt u de pagina BuitenOpOrde, dan
        herkent de website met welk apparaat u de pagina bezoekt. Doet u dit met een mobiele
        telefoon of tablet, dan bieden wij u de mogelijkheid om deze pagina voortaan als een app te
        gebruiken. Op dat moment kunt u gebruik maken van extra mogelijkheden.
      </StyledParagraph>
      <StyledHeading>Locatie via gps, kaart of invoeren</StyledHeading>
      <StyledParagraph>
        U kunt gebruik maken van de mogelijkheid om uw locatie automatisch op te laten zoeken.
        Hiervoor moet u eenmalig de browser toestemming geven om uw locatiegegevens te gebruiken. U
        klikt hiervoor op <span className="geo-marker" /> in de kaart. U geeft daarna toestemming
        aan https://db.lansingerland.nl om uw huidige locatie te gebruiken. Daarna slaat uw browser
        uw gps gegevens op. De app zoomt daarna in op uw huidige locatie. U kunt ook zelf een
        locatie aangeven. Type de naam van een straat of klik op de kaart.
      </StyledParagraph>
      <StyledHeading>Foto&apos;s</StyledHeading>
      <StyledParagraph>
        Gebruikt u BuitenOpOrde als app, dan kunt u foto’s maken met uw mobiele apparaat en deze
        meteen aan de melding toevoegen. U geeft de app dan eenmalig toestemming om gebruik te maken
        van de camera van uw apparaat. Gebruikt u een desktop, dan voegt u een foto toe via uw
        bestanden.
      </StyledParagraph>
      <StyledHeading>Privacy en gebruik van cookies</StyledHeading>
      <StyledParagraph>
        BuitenOpOrde als app biedt u de mogelijkheid om uw persoonlijke gegevens (naam, adres,
        woonplaats telefoonnummer) op te slaan. We gebruiken hiervoor de opslagcapaciteit van uw
        browser. Zo kunt u een volgende keer makkelijker een melding maken. Deze gegevens staan op
        uw eigen mobiele apparaat en zijn niet door de gemeente in te zien. Wij ontvangen uw
        gegevens pas op het moment dat u een melding aan ons door geeft. De ontvangen gegevens
        gebruiken wij alleen voor het afhandelen van de betreffende melding in het zaaksysteem. De
        gegevens delen wij niet met derden. U kunt uw instellingen altijd wijzigen, door de "Cookies
        en andere website data" in uw browser te wissen. Meer informatie over het gebruik van
        persoonsgegevens is te vinden in de{' '}
        <a
          href="https://www.lansingerland.nl/privacyverklaring"
          target="_blank"
          rel="noopener noreferrer"
        >
          privacyverklaring
        </a>
        .
      </StyledParagraph>
      <StyledParagraph>Versie {config.VERSION}</StyledParagraph>
    </div>
  );

  const modalProps = {
    ariaLabel: 'Buiten Op Orde informatie',
    triggerText: 'Info',
  };

  return (
    <StyledHeader>
      <StyledLink href={config.LOGO.URL}>
        <StyledLogo
          src={theme.document.logo}
          alt={`Logo van ${config.COMMUNE_NAME}, naar de website van ${
            config.COMMUNE_NAME
          }`}
        />
      </StyledLink>
      <Modal {...modalProps}>{modalContent}</Modal>
    </StyledHeader>
  );
};

export default withTheme(Header);
