import { CLEAR_GEO_STORE, SAVE_GEO } from '../actions/types';
import geo from '../store/initialStates/geo';

const initialState = geo;

export default function geoReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_GEO:
      /**
       * delete digits after 7 decimal point because Zaaksysteem cannot handle
       * accurate geo coordinates
       */
      let lat = action.payload.lat;
      lat = parseFloat(lat.toString().match(/\d+\.\d{3,7}/));

      let lng = action.payload.lng;
      lng = parseFloat(lng.toString().match(/\d+\.\d{3,7}/));

      return {
        lat,
        lng,
        name: action.payload.name,
      };
    case CLEAR_GEO_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
