import { FETCH_CATEGORIES } from '../actions/types';

const initialState = {};

export default function categoriesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CATEGORIES:
      return [...action.payload];
    default:
      return state;
  }
}
