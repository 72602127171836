import axios from 'axios';

import {
  CLEAR_FORM_STORE_ALL,
  CLEAR_FORM_STORE_SOME,
  FETCH_CATEGORIES,
  GET_FORM_STORE,
  SAVE_FORM,
  UPDATE_FORM_STORE
} from './types';

const API_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchCategories = () => dispatch =>
  axios
    .get(`${API_URL}/api/v1/notification/categories`)
    .then(res =>
      dispatch({
        type: FETCH_CATEGORIES,
        payload: res.data
      })
    )
    .catch(error => {
      throw error;
    });

export function saveForm(form) {
  return {
    type: SAVE_FORM,
    form
  };
}

export function getFormStore() {
  return {
    type: GET_FORM_STORE
  };
}

export const updateFormStore = newAttributes => dispatch => {
  dispatch({
    type: UPDATE_FORM_STORE,
    newAttributes
  });
  return Promise.resolve();
};

/**
 * clearFormStoreAll - called at form completion.
 * The user has denied the storage of any data, so remove it all at the end.
 */
export function clearFormStoreAll() {
  return {
    type: CLEAR_FORM_STORE_ALL
  };
}

/**
 * clearFormStoreSome - called at form completion.
 * The user has accepted the storage of data, so we remove the data we don't need anymore
 * but keep the user data for next time.
 */
export function clearFormStoreSome() {
  return {
    type: CLEAR_FORM_STORE_SOME
  };
}
