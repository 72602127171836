import React, { PureComponent } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import styled from 'styled-components';
import { uid } from 'react-uid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import config from '../../config';
import isEmpty from '../../utils/is-empty';
import { clearFormStoreAll, clearFormStoreSome } from '../../actions/formActions';
import { clearGeoStore } from '../../actions/geoActions';
import FormatBytes from '../../components/FormatBytes';
import ViewWrapper from '../../components/ViewWrapper';
import Navigation from '../../components/Navigation';
import Heading from '../../components/common/Heading';
import hasSavePermission from '../../utils/permissions';

const API_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * Styling of the component.
 *
 * @type {Object}
 */
const StyledSection = styled.section`
  border-bottom: ${props => props.theme.stepVerifySections.borderBottom};
  margin: ${props => props.theme.stepVerifySections.margin};
`;

const StyledInnerSection = styled.div`
  margin: ${props => props.theme.stepVerifySections.margin};
`;

const StyledHeading = styled.h3`
  font-size: 18px;
`;

const StyledParagraph = styled.p`
  font-size: ${props => props.theme.stepVerifyParagraph.fontSize};
  margin: ${props => props.theme.stepVerifyParagraph.margin};
`;

const StyledUploadText = styled.span`
  font-size: ${props => props.theme.stepVerifyParagraph.fontSize};
`;

const StyledError = styled.div`
  background-color: ${props => props.theme.errorMessageApi.backgroundColor};
  border: 1px solid ${props => props.theme.errorMessageApi.color};
  font-size: ${props => props.theme.errorMessageApi.fontSize};
  margin-bottom: ${props => props.theme.errorMessageApi.marginBottom};
  padding: 10px;
`;

const StyledUpload = styled.div`
  > * {
    margin-right: 10px;
  }
`;

class StepFour extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      isSubmitting: false,
    };

    this.handleError = this.handleError.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.setState({ error: false });
  }

  /**
   * Handle error
   * @return {Void}
   */
  handleError = () => {
    this.setState({ error: true, isSubmitting: false });
  };

  /**
   * Handle submit
   * @param {Object} event
   */
  handleSubmit = () => {
    const { props } = this;
    const { form, geo, history } = this.props;
    const { uploads } = form;

    this.setState({ isSubmitting: true });

    const imageCollection = [];

    uploads.forEach(file => {
      imageCollection.push(file.url);
    });

    const formData = new FormData();
    formData.append('address', form.address);
    formData.append('category', form.category);
    formData.append('city', form.city);
    formData.append('description', form.description);
    formData.append('email', form.email);
    formData.append('location', form.location);
    formData.append('location_description', form.locationDescription);
    formData.append('location_coordinates', [geo.lat, geo.lng]);
    formData.append('app_version', config.VERSION);

    formData.append('name', form.name);

    if (!isEmpty(imageCollection)) {
      imageCollection.forEach(item => {
        formData.append('photo[]', item);
      });
    }

    formData.append('phone_number', form.phoneNumber);
    formData.append('status_updates', form.statusUpdates);

    axios
      .post(`${API_URL}/api/v1/notification`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        if (!hasSavePermission()) {
          props.clearFormStoreAll();
        } else {
          props.clearFormStoreSome();
        }
        props.clearGeoStore(this.state);
        this.setState({ isSubmitting: false });
        history.push('/bedankt');
      })
      .catch(err => this.handleError(err));
  };

  /**
   * Render the location view.
   *
   * @return {Object}
   */
  render() {
    const { error, isSubmitting } = this.state;
    const { form } = this.props;

    return (
      <ViewWrapper>
        <Heading center size={2} title="Gegevens controleren" />
        {error && (
          <StyledError>
            Er ging iets mis bij het verzenden van de gegevens. Probeer het later nog eens. Onze
            excuses voor het ongemak.
          </StyledError>
        )}
        <StyledSection>
          <StyledHeading>Waar is het?</StyledHeading>
          <StyledInnerSection>
            {form.location && <StyledParagraph>{form.location}</StyledParagraph>}
            {form.locationDescription && (
              <StyledParagraph>{form.locationDescription}</StyledParagraph>
            )}
          </StyledInnerSection>
        </StyledSection>
        <StyledSection>
          <StyledInnerSection>
            <StyledHeading>Waar gaat het om?</StyledHeading>
            {form.category && <StyledParagraph>{form.category}</StyledParagraph>}
          </StyledInnerSection>
          <StyledInnerSection>
            <StyledHeading>Omschrijving</StyledHeading>
            {form.description && <StyledParagraph>{form.description}</StyledParagraph>}
          </StyledInnerSection>
        </StyledSection>
        <StyledSection>
          <StyledInnerSection>
            <StyledHeading>Foto&apos;s</StyledHeading>
            {form.uploads &&
              form.uploads.map(file => (
                <StyledUpload key={uid(file)}>
                  <FontAwesomeIcon icon="camera" />
                  <StyledUploadText>{file.name}</StyledUploadText>
                  <FormatBytes bytes={file.size} />
                </StyledUpload>
              ))}
          </StyledInnerSection>
        </StyledSection>
        <StyledSection>
          <StyledInnerSection>
            <StyledHeading>Mijn gegevens</StyledHeading>
            {form.name && <StyledParagraph>{form.name}</StyledParagraph>}
            {form.address && <StyledParagraph>{form.address}</StyledParagraph>}
            {form.city && <StyledParagraph>{form.city}</StyledParagraph>}
            {form.phoneNumber && <StyledParagraph>{form.phoneNumber}</StyledParagraph>}
            {form.email && form.statusUpdates === 'Ja' && (
              <StyledParagraph>{form.email}</StyledParagraph>
            )}
          </StyledInnerSection>
        </StyledSection>
        <StyledSection>
          <StyledHeading>Op de hoogte blijven</StyledHeading>
          <StyledInnerSection>
            {form.statusUpdates && <StyledParagraph>{form.statusUpdates}</StyledParagraph>}
          </StyledInnerSection>
        </StyledSection>
        <Navigation finalStep onClick={this.handleSubmit} isLoading={isSubmitting} />
      </ViewWrapper>
    );
  }
}

StepFour.propTypes = {
  geo: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    name: PropTypes.string,
  }),
  form: PropTypes.shape({
    address: PropTypes.string,
    category: PropTypes.string,
    city: PropTypes.string,
    description: PropTypes.string,
    explanation: PropTypes.string,
    location: PropTypes.string,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
  }).isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

StepFour.defaultProps = {
  geo: {
    lat: null,
    lng: null,
    name: null,
  },
};

const mapDispatchToProps = {
  clearFormStoreAll,
  clearFormStoreSome,
  clearGeoStore,
};
const mapStateToProps = state => ({
  form: state.form,
  geo: state.geo,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StepFour));
