import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';

import iosShare from '../img/ios-share.png';

/**
 * Styling of the component.
 *
 * @type {Object}
 */
const StyledDialog = styled.div`
  align-items: center;
  background: ${props => props.theme.addToHomeScreen.backgroundColor};
  border: ${props => props.theme.addToHomeScreen.border};
  border-radius: ${props => props.theme.addToHomeScreen.borderRadius};
  bottom: ${props => props.theme.addToHomeScreen.bottom};
  color: ${props => props.theme.addToHomeScreen.color};
  display: flex;
  left: ${props => props.theme.addToHomeScreen.left};
  min-height: ${props => props.theme.addToHomeScreen.minHeight};
  padding: ${props => props.theme.addToHomeScreen.padding};
  position: fixed;
  right: ${props => props.theme.addToHomeScreen.right};
  z-index: 9997;
`;

const StyledParagraph = styled.p`
  display: inline-block;
  font-size: 16px;
  margin: 0;
`;

const StyledContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const StyledLogo = styled.span`
  background: url("${props => props.theme.modalTrigger.infoIcon}") no-repeat;
  background-size: contain;
  display: inline-block;
  height: 40px;
  margin-right: 5px;
  width: 40px;
`;

/* eslint-disable */
const StyledIcon = styled.i`
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline-block;
  height: 20px;
  width: 20px;

  ${icon =>
    (icon =
      'iosShare' &&
      css`
        background-image: url(${iosShare});
      `)}
`;
/* eslint-enable */

const StyledClose = styled.p`
  background-color: ${props => props.theme.addToHomeScreenClose.backgroundColor};
  cursor: pointer;
  font-size: 16px;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px 10px;
`;

/**
 * Add to home screen.
 *
 * @desc component for iOS, since iOS has nothing native as of yet.
 */
class AddToHomeScreen extends Component {
  /**
   * Initialize state.
   *
   * @type {Object}
   */
  constructor(props) {
    super(props);

    this.state = {
      showInstallMessage: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };

    // Detects if device is in standalone mode
    const isInStandaloneMode = () =>
      'standalone' in window.navigator && window.navigator.standalone;

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      this.setState({ showInstallMessage: true });
    }
  }

  handleClick() {
    this.setState({ showInstallMessage: false });
  }

  render() {
    const { showInstallMessage } = this.state;

    if (showInstallMessage) {
      return (
        <Fragment>
          <StyledDialog>
            <StyledClose onClick={this.handleClick}>X</StyledClose>
            <StyledContent>
              <StyledLogo />
              <StyledParagraph>
                Installeer deze app op je iPhone, tik <StyledIcon className={iosShare} /> en dan
                &apos;Zet op beginscherm&apos;
              </StyledParagraph>
            </StyledContent>
          </StyledDialog>
        </Fragment>
      );
    }

    return null;
  }
}

export default AddToHomeScreen;
