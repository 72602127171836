import validator from 'validator';
import isEmpty from '../is-empty';

const validateStepOne = data => {
  const errors = {};

  // Set default values in case of empty
  data.category = !isEmpty(data.category) ? data.category : '';
  data.location = !isEmpty(data.location) ? data.location : '';
  data.locationDescription = !isEmpty(data.locationDescription) ? data.locationDescription : '';

  // Error cases
  if (validator.isEmpty(data.category) || data.category === 'default') {
    errors.category = 'Gelieve een categorie te kiezen.';
  }
  
  if (validator.isEmpty(data.location) && validator.isEmpty(data.locationDescription)) {
    errors.locationDescription =
      'Het aanklikken van een locatie op de kaart, het invullen van een straat of het invullen van de omschrijving is verplicht.';
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default validateStepOne;
