import { Component } from 'react';
import PropTypes from 'prop-types';
import { withLeaflet } from 'react-leaflet';
import Locate from 'leaflet.locatecontrol';
import 'leaflet.locatecontrol/dist/L.Control.Locate.css';

class LocateControl extends Component {
  componentDidMount() {
    const { leaflet, options, startDirectly } = this.props;
    const { map } = leaflet;

    const lc = new Locate(options);
    lc.addTo(map);
    
    if (startDirectly) {
      lc.start();
    }
  }

  render() {
    return null;
  }
}

LocateControl.propTypes = {
  leaflet: PropTypes.instanceOf(Object).isRequired,
  options: PropTypes.instanceOf(Object),
  startDirectly: PropTypes.bool,
};

LocateControl.defaultProps = {
  options: {},
  startDirectly: false,
};

export default withLeaflet(LocateControl);
