import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Styling of the component.
 *
 * @type {Object}
 */
const StyledButton = styled.button`
  background: ${props => props.theme.buttonSolid.backgroundColor};
  border: 2px solid ${props => props.theme.buttonSolid.borderColor};
  border-radius: ${props => props.theme.buttonSolid.borderRadius};
  color: ${props => props.theme.buttonSolid.color};
  cursor: pointer;
  display: inline-block;
  font-size: ${props => props.theme.buttonSolid.fontSize};
  font-weight: 400;
  overflow: hidden;
  padding: ${props => props.theme.buttonSolid.padding};
  position: relative;
  text-align: center;
  transition: all 0.3s ease;
  white-space: nowrap;
  vertical-align: middle;

  &:focus,
  &:hover {
    background: ${props => props.theme.buttonSolidHover.backgroundColor};
    color: ${props => props.theme.buttonSolidHover.borderColor};
  }
`;

class ButtonSolid extends PureComponent {
  /**
   * Define types of props.
   *
   * @type {Object}
   */
  static propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string,
  };

  /**
   * Default values of optional props.
   *
   * @type {Object}
   */
  static defaultProps = {
    icon: null,
  };

  /**
   * Render the button.
   *
   * @return {Object}
   */
  render() {
    const { icon, onClick, title } = this.props;

    return (
      <StyledButton type="button" onClick={onClick}>
        <FontAwesomeIcon icon={icon} />
        {` `}
        {title}
      </StyledButton>
    );
  }
}

export default ButtonSolid;
