import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import throttle from 'lodash/throttle';

import { loadState, saveState } from '../utils/local-storage';
import initialState from './initialState';
import rootReducer from '../reducers';

const persistedState = loadState();
const middleware = [thunk];

// Decide which state to use
function selectAppState(initial, persisted) {
  if (persisted === undefined) {
    return initial;
  }
  return persisted;
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const configureStore = createStore(
  rootReducer,
  selectAppState(initialState, persistedState),
  composeEnhancers(applyMiddleware(...middleware))
);

configureStore.subscribe(
  throttle(() => {
    saveState(configureStore.getState());
  }, 1000)
);

export default configureStore;
