import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import validateStepTwo from '../../utils/validation/step-two';
import { updateFormStore } from '../../actions/formActions';
import ViewWrapper from '../../components/ViewWrapper';
import Navigation from '../../components/Navigation';
import Heading from '../../components/common/Heading';
import TextArea from '../../components/common/TextArea';
import InputWrapper from '../../components/InputWrapper';
import Upload from '../../components/common/Upload';

/**
 * Styling of the component.
 *
 * @type {Object}
 */
const OuterContainer = styled.form`
  height: 100%;
  max-width: 100%;
`;

const StyledFeedback = styled.div`
  margin: ${props => props.theme.errorMessageFeedback.margin};
  font-size: ${props => props.theme.errorMessageFeedback.fontSize};
  color: ${props => props.theme.errorMessageFeedback.color};
`;

class StepTwo extends Component {
  /**
   * Initialize state.
   *
   * @type {Object}
   */
  constructor(props) {
    super(props);

    const { form } = this.props;

    this.state = {
      errors: form.errors,
      description: form.description,
      uploads: form.uploads,
      stepValidated: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateStep = this.validateStep.bind(this);
  }

  /**
   * Executed once the component is mounted.
   *
   * @return {Void}
   */
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   * Update the local form state on every change.
   * @param {Object} event
   */
  handleChange = e => {
    const { name, value } = e.target;

    this.setState({ [name]: value }, this.validateStep);
  };

  /**
   * Validate the current step, and decide wether or not the user
   * may continue to the next step
   *
   * @return {Void}
   */
  validateStep = () => {
    const { description } = this.state;
    const { errors, isValid } = validateStepTwo({ description });

    this.setState({ errors });

    if (isValid) {
      this.setState({ stepValidated: true });
    }
  };

  /**
   * Update the Redux state and update the local state with the same props
   * this ensures that both contain the same data.
   * @param {Object} event
   */
  handleSubmit = e => {
    e.preventDefault();
    const { props } = this;
    const { stepValidated } = this.state;

    this.validateStep();

    if (stepValidated) {
      props.updateFormStore(this.state);
    }
  };

  render() {
    const { form } = this.props;
    const { description, errors, stepValidated } = this.state;

    return (
      <OuterContainer key="form-step-two" onSubmit={this.handleSubmit}>
        <ViewWrapper>
          <Heading center size={2} title="Waar gaat het om?" />
          <InputWrapper>
            <TextArea
              error={errors.description}
              name="description"
              onChange={this.handleChange}
              placeholder="Omschrijving van de melding"
              rows="4"
              value={description}
            />
          </InputWrapper>
          <Heading center size={2} title="Wilt u een foto meesturen?" />
          <InputWrapper>
            <Upload
              buttonText="Voeg een of meerdere foto's toe"
              data={form.uploads}
              onChange={this.handleChange}
            />
          </InputWrapper>
          <StyledFeedback>* verplicht veld</StyledFeedback>
          <Navigation stepValidated={stepValidated} />
        </ViewWrapper>
      </OuterContainer>
    );
  }
}

StepTwo.propTypes = {
  form: PropTypes.shape({
    description: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => ({
  form: state.form,
});

const mapDispatchToProps = {
  updateFormStore,
};

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
