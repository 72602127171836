const hasSavePermission = () => {
  const savePermission = localStorage.getItem('permissionToSave');

  if (savePermission && savePermission === 'true') {
    return true;
  }

  return false;
};

export default hasSavePermission;
