import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ViewWrapper from '../../components/ViewWrapper';
import Heading from '../../components/common/Heading';

/**
 * Styling of the component.
 *
 * @type {Object}
 */
const StyledText = styled.p`
  margin-bottom: 60px;
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.link.color};
`;

class NotFound extends Component {
  /**
   * Types of props.
   *
   * @type {Object}
   */
  static propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
  };

  constructor(props) {
    super(props);

    this.routeChange = this.routeChange.bind(this);
  }

  routeChange = () => {
    const { history } = this.props;
    history.push('/');
  };

  render() {
    return (
      <ViewWrapper center>
        <Heading size={2} title="Oeps, dit was niet de bedoeling!" />
        <StyledText>
          Deze pagina is helaas niet gevonden. Mogelijk bestaat de pagina niet meer of is er iets
          mis gegaan bij het intypen van de URL.
        </StyledText>
        <StyledLink to="/melding-maken">Een melding maken</StyledLink>
      </ViewWrapper>
    );
  }
}

export default NotFound;
