import validator from 'validator';
import isEmpty from '../is-empty';

const validateStepTwo = data => {
  const errors = {};

  // Set default values in case of empty
  data.description = !isEmpty(data.description) ? data.description : '';

  // Error cases
  if (validator.isEmpty(data.description)) {
    errors.description = `Het veld 'Omschrijving' is verplicht.`;
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default validateStepTwo;
