import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCamera,
  faLongArrowLeft,
  faLongArrowRight,
  faMapMarkerAlt,
  faPlus,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons';

library.add(faCamera, faLongArrowLeft, faLongArrowRight, faMapMarkerAlt, faPlus, faTimesCircle);
