import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import formatBytes from '../utils/format-bytes';

/**
 * Styling of the component.
 *
 * @type {Object}
 */
const StyledWrapper = styled.span`
  font-size: 14px;
`;

const FormatBytes = ({ bytes }) => <StyledWrapper>{formatBytes(bytes, 1)}</StyledWrapper>;

FormatBytes.propTypes = {
  bytes: PropTypes.node.isRequired,
};

export default FormatBytes;
