import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

/**
 * Styling of the component.
 *
 * @type {Object}
 */
const StyledIntro = styled.h1`
  align-items: center;
  display: flex;
  font-size: ${props => props.theme.introText.fontSize};
  font-weight: ${props => props.theme.introText.fontWeight};
  max-width: ${props => props.theme.introText.maxWidth};
  margin: ${props => props.theme.introText.margin};
  padding: ${props => props.theme.introText.padding};
`;

const IntroText = ({ text }) => <StyledIntro>{text}</StyledIntro>;

IntroText.propTypes = {
  text: PropTypes.string,
};

IntroText.defaultProps = {
  text: '',
};

export default IntroText;
