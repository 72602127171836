import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Styling of the component.
 *
 * @type {Object}
 */
const StyledButton = styled.button`
  background: transparent;
  border: 0;
  border-radius: 25px;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.5rem 55px 0.5rem 1rem;
  position: relative;
  overflow: hidden;
  text-align: center;
  transition: all 0.3s ease;
  vertical-align: middle;
  white-space: nowrap;

  &:focus,
  &:hover,
  &.btn--filled {
    background: #0d8228;
    color: #ffffff;
  }

  &.btn--filled:focus,
  &.btn--filled:hover {
    background: #3a9139;
  }

  svg {
    position: absolute;
    z-index: 1;
    font-size: 1.1em;
    right: 0.9rem;
    color: #ffffff;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -10px;
    display: block;
    width: 50px;
    height: 100%;
    background: #0d8228;
    transform: skew(-35deg);
  }

  &.btn--gray::after {
    background: #e6e6e6;
  }

  &.btn--gray:focus,
  &.btn--gray:hover {
    color: #000000;
    background: #e6e6e6;
  }

  &.btn--left {
    padding: 0.5rem 1rem 0.5rem 55px;
  }

  &.btn--left::after {
    top: 0;
    left: -10px;
  }

  &.btn--left svg {
    left: 0.9rem;
  }
`;

class Button extends PureComponent {
  /**
   * Define types of props.
   *
   * @type {Object}
   */
  static propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    filled: PropTypes.bool,
    gray: PropTypes.bool,
    icon: PropTypes.string,
    left: PropTypes.bool,
  };

  /**
   * Default values of optional props.
   *
   * @type {Object}
   */
  static defaultProps = {
    onClick: null,
    type: 'button',
    filled: false,
    gray: false,
    icon: null,
    left: false,
  };

  /**
   * Get the classes of the button.
   *
   * @return {String}
   */
  getClassName() {
    const { filled, left, gray } = this.props;

    const classes = ['btn'];

    if (filled) {
      classes.push('btn--filled');
    }

    if (left) {
      classes.push('btn--left');
    }

    if (gray) {
      classes.push('btn--gray');
    }

    return classes.join(' ');
  }

  /**
   * Get the icon associated with the button.
   *
   * @return {String}
   */
  getIcon() {
    const { icon, left } = this.props;

    if (icon) {
      return icon;
    }

    return left ? 'long-arrow-left' : 'long-arrow-right';
  }

  /**
   * Render the button.
   *
   * @return {Object}
   */
  render() {
    const { onClick, title, type } = this.props;

    return (
      <StyledButton
        type={type}
        onClick={onClick}
        className={this.getClassName()}
      >
        {title}
        <FontAwesomeIcon icon={this.getIcon()} />
      </StyledButton>
    );
  }
}

export default Button;
