import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Styling of the component.
 *
 * @type {Object}
 */
const StyledSelectGroup = styled.div`
  position: relative;
  width: 100%;

  svg {
    pointer-events: none;
    position: absolute;
    top: 42px;
    right: 16px;
  }
`;

const StyledSelect = styled.select`
  appearance: none;

  background: ${props => props.theme.select.background};
  border-radius: ${props => props.theme.select.borderRadius};
  border: ${props => props.theme.select.border};
  border-color: ${props => (props.showError ? props.theme.select.borderError : '')};
  padding: ${props => props.theme.select.padding};
  line-height: ${props => props.theme.select.lineHeight};
  font-size: ${props => props.theme.select.fontSize};
  width: ${props => props.theme.select.width};
  height: ${props => props.theme.select.height};
  margin: ${props => props.theme.select.margin};
  max-width: ${props => props.theme.select.maxWidth};

  ::-ms-expand {
    display: none;
  }

  option {
    color: ${props => props.theme.select.color};
  }

  option[value=''][disabled] {
    display: none;
  }

  &:focus {
    box-shadow: 0 0 4px ${props => props.theme.input.focus};
    border: 1px solid ${props => props.theme.input.focus};
  }
`;

const StyledLabel = styled.label`
  display: inline-block;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 15px;
  color: #707070;
`;

const StyledFeedback = styled.div`
  margin: ${props => props.theme.errorMessageFeedback.margin};
  font-size: ${props => props.theme.errorMessageFeedback.fontSize};
  color: ${props => props.theme.errorMessageFeedback.color};
`;

const StyledRequired = styled.span`
  color: ${props => props.theme.requiredAsteriks.color};
  position: absolute;
  right: -10px;
  top: -10px;
`;

const SelectArrow = () => (
  <svg fill="#676767" width="36" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 10l5 5 5-5z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

/**
 * An internal error handler to make sure we always pass
 * the Select component Field an array of options
 */
const handlePropsError = options => {
  if (!options && !Array.isArray(options)) {
    throw new Error('Select component requires an array of options passed as an options prop');
  }
};

class Select extends PureComponent {
  render() {
    const { error, forceAcceptEmpty, name, onChange, options, value } = this.props;

    handlePropsError(options);

    return (
      <StyledSelectGroup>
        <StyledLabel htmlFor={name}>Kies een categorie</StyledLabel>
        <StyledSelect id={name} error={error} name={name} value={value} onChange={onChange}>
          <option disabled value="default">
            Categorieën
          </option>
          {options.map((option, index) => {
            /**
             * We're allowing for objects to be passed in as value to these select
             * option input fields. To do so, the object must be JSON (a string)
             * to be stored within the HTML element.
             */
            const val =
              typeof option.value === 'object' ? JSON.stringify(option.value) : option.value;

            return (
              <option
                disabled={option.disabled}
                key={index.toString()}
                value={val}
                data-value={val}
              >
                {option}
              </option>
            );
          })}
        </StyledSelect>
        <SelectArrow />
        {!forceAcceptEmpty && <StyledRequired>*</StyledRequired>}
        {error && <StyledFeedback role="alert">{error}</StyledFeedback>}
      </StyledSelectGroup>
    );
  }
}

Select.propTypes = {
  error: PropTypes.string,
  forceAcceptEmpty: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  value: PropTypes.string,
};

Select.defaultProps = {
  error: '',
  forceAcceptEmpty: false,
  value: 'default',
  options: null,
};

export default Select;
