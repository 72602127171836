import React, { Component, createRef } from 'react';
import { Map as LeafMap, TileLayer, Marker, Popup } from 'react-leaflet';
import PropTypes from 'prop-types';
import L from 'leaflet';
import styled from 'styled-components';
import 'proj4';
import 'proj4leaflet';
import 'leaflet-gesture-handling';

import config from '../../config';
import locationPin from '../../img/pin-red.svg';
import spinner from '../../img/spinner.svg';

import LocateControl from './LocateControl';

const styles = {
  mapStyle: {
    height: '350px',
    width: '100%',
  },
};

/**
 * Styling of the component.
 *
 * @type {Object}
 */

const Container = styled.div`
  position: relative;

  .geo-marker {
    background-image: url(${locationPin});
    background-size: 14px;
    display: inline-block;
    height: 29px;
    width: 20px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .geo-loading {
    background-image: url(${spinner});
    background-size: 14px;
    display: inline-block;
    height: 29px;
    width: 20px;
    background-repeat: no-repeat;
    background-position: center;
    animation: spin 4s linear infinite;

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }

  /**
   * A11y improvements
   */
  .leaflet-control-attribution a {
    text-decoration: underline !important;
  }

  .leaflet-control-attribution a,
  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    &:focus {
      outline: 2px solid ${props => props.theme.input.focus}!important; // Overwrite inline styles
    }
  }
`;

/**
 * Custom map marker
 */
// eslint-disable-next-line
const customMarker = new L.icon({
  iconUrl: locationPin,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lat: '',
      lng: '',
      zoom: 10,
      maxZoom: 15,
      minZoom: 7,
    };

    this.mapRef = createRef();
  }

  /**
   * Because the step components get unmounted when you move to the next/prev step
   * We set whatever was stored in the lat/lng as the initial lat/lng and
   * update the marker to center on that location.
   */
  componentDidMount() {
    const { geoData } = this.props;
    const { lat, lng } = geoData;

    const event = {
      latlng: {
        lat,
        lng,
      },
    };

    this.removeMapFromTabOrder();
    this.setState({ lat, lng });
    this.updateMarker(event);
    this.updateInnerMarkerTabIndex();
  }

  componentDidUpdate(prevProps) {
    const { geoData } = this.props;

    if (prevProps.geoData.lat !== geoData.lat && prevProps.geoData.lng !== geoData.lng) {
      // eslint-disable-next-line
      this.setState({ lat: geoData.lat, lng: geoData.lng });
    }
  }

  /**
   * A11y modification to remove inner map elements from tab order
   * Since we cannot acces them via react, we use querySelector
   */
  updateInnerMarkerTabIndex = () => {
    const interval = setInterval(() => {
      const mapElement = document.querySelectorAll('#map *');
      if (mapElement.length > 0) {
        clearInterval(interval);
        mapElement.forEach(child => {
          child.setAttribute('tabindex', '-1');
        });
      }
    }, 100);
  };

  updateMarker = event => {
    const currentZoomLevel = this.mapRef.current.leafletElement.getZoom();

    this.setState({
      lat: event.latlng.lat,
      lng: event.latlng.lng,
      zoom: currentZoomLevel,
    });
  };

  removeMapFromTabOrder = () => {
    const mapContainer = this.mapRef.current.leafletElement._container;
    mapContainer.setAttribute('tabindex', '-1');
  };

  handleLocationFound = event => {
    const { props } = this;
    const { lat, lng } = event.latlng;

    // Stop tracking location
    const map = this.mapRef.current.leafletElement;
    map.stopLocate();

    this.updateMarker(event);

    props.onClick(lat, lng);
  };

  handleOnClick = event => {
    const { props } = this;
    const { lat, lng } = event.latlng;

    this.updateMarker(event);

    props.onClick(lat, lng);
  };

  render() {
    const { lat, lng, maxZoom, minZoom, zoom } = this.state;
    const { crs } = this.props;
    const position = [lat, lng];
    const { mapStyle } = styles;

    const locateOptions = {
      icon: 'geo-marker',
      iconLoading: 'geo-loading',
      returnToPrevBounds: true,
      cacheLocation: true,
      enableHighAccuracy: true,
      position: 'topleft',
      onLocationError() {
        alert(
          'We kunnen uw locatie niet opvragen, controleer of de locatievoorzieningen van uw browser toegestaan worden.'
        );
      },
      strings: {
        title: 'Gebruik mijn geo-locatie',
        metersUnit: 'meter',
        outsideMapBoundsMsg: 'Je lijkt buiten de grenzen van de kaart te zijn gelokaliseerd',
        popup: 'Je bent binnen {distance} {unit} van dit punt',
      },
    };

    const gestureHandlingOptions = {
      duration: 5000,
      text: {
        touch: 'Gebruik twee vingers om de kaart te verplaatsen',
        scroll: 'Gebruik ctrl + scroll om in- en uit te zoomen op de kaart',
        scrollMac: 'Gebruik \u2318 + scroll om in- en uit te zoomen op de kaart',
      },
    };

    return (
      <Container tabIndex="-1">
        <LeafMap
          center={[lat, lng]}
          crs={crs}
          dragging
          gestureHandling
          gestureHandlingOptions={gestureHandlingOptions}
          doubleClickZoom={false}
          id="map"
          onClick={this.handleOnClick}
          onLocationfound={this.handleLocationFound}
          zoom={zoom}
          maxZoom={maxZoom}
          minZoom={minZoom}
          ref={this.mapRef}
          returnToPrevBounds={false}
          scrollWheelZoom={false}
          style={mapStyle}
          tap
          touchZoom
          tabIndex="-1"
        >
          <TileLayer
            url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
            attribution='Achtergrondkaarten: <a href="https://www.pdok.nl/">PDOK</a>'
          />
          <TileLayer url="https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0/standaard/EPSG:28992/{z}/{x}/{y}.png" />
          <TileLayer url="https://service.pdok.nl/lv/bgt/wmts/v1_0/achtergrondvisualisatie/EPSG%3A28992/{z}/{x}/{y}.png" />
          <LocateControl
            handleLocationFound={this.handleLocationFound}
            options={locateOptions}
            startDirectly={false}
          />
          <Marker position={position} icon={customMarker}>
            <Popup>De locatie waar u een melding over wilt maken.</Popup>
          </Marker>
        </LeafMap>
      </Container>
    );
  }
}

Map.propTypes = {
  crs: PropTypes.instanceOf(Object),
  geoData: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    name: PropTypes.string,
  }),
};

Map.defaultProps = {
  crs: new L.Proj.CRS(
    'EPSG:28992',
    '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +units=m +towgs84=565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812 +no_defs',
    {
      resolutions: [
        3440.64,
        1720.32,
        860.16,
        430.08,
        215.04,
        107.52,
        53.76,
        26.88,
        13.44,
        6.72,
        3.36,
        1.68,
        0.84,
        0.42,
        0.21,
        0.105,
      ],
      bounds: L.bounds([-285401.92, 903401.92], [595401.92, 22598.08]),
      origin: [-285401.92, 903401.92],
    }
  ),
  geoData: {
    lat: config.MAP.LAT,
    lng: config.MAP.LNG,
    name: '',
  },
};

export default Map;
