import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import * as focusTrap from 'focus-trap';

import media from '../constants';

/**
 * Styling of the component.
 *
 * @type {Object}
 */
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: ${props => props.theme.permissionBar.backgroundColor};
  border-top: ${props => props.theme.permissionBar.border};
  color: ${props => props.theme.permissionBar.color};
  padding: ${props => props.theme.permissionBar.padding};
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9998;

  ${media.tablet`
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  `};
`;

const StyledParagraph = styled.p`
  margin: ${props => props.theme.permissionBar.margin};

  ${media.tablet`
    margin: 0;
  `};
`;

const StyledControls = styled.div`
  display: flex;
  justify-content: space-around;

  ${media.tablet`
    justify-content: flex-start;
  `};
`;

const StyledLink = styled.button`
  background: ${props => props.theme.permissionBarButton.backgroundColor};
  border: 0;
  border-radius: ${props => props.theme.permissionBarButton.borderRadius};
  color: ${props => props.theme.permissionBarButton.color};
  font-size: ${props => props.theme.document.fontSize};
  padding: ${props => props.theme.permissionBarButton.padding};

  &:focus,
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const StyledButton = styled.button`
  background: ${props => props.theme.permissionBarButton.backgroundColor};
  border: ${props => props.theme.permissionBarButton.border};
  border-radius: ${props => props.theme.permissionBarButton.borderRadius};
  color: ${props => props.theme.permissionBarButton.color};
  font-size: ${props => props.theme.document.fontSize};
  padding: ${props => props.theme.permissionBarButton.padding};

  &:focus,
  &:hover {
    background-color: ${props => props.theme.permissionBarButton.backgroundColorHover};
    color: ${props => props.theme.permissionBarButton.colorHover};
    cursor: pointer;
  }
`;

class PermissionBar extends Component {
  /**
   * Initialize state.
   *
   * @type {Object}
   */
  constructor(props) {
    super(props);

    const hasSavePermission = localStorage.getItem('permissionToSave');

    if (hasSavePermission) {
      this.state = {
        visible: false,
      };
    } else {
      this.state = {
        visible: true,
      };
    }

    this.handleAccept = this.handleAccept.bind(this);
    this.handleRefuse = this.handleRefuse.bind(this);
    this.focusTrapInstance = null;
  }

  componentDidMount() {
    const { visible } = this.state;
    if (visible) {
      this.activateFocusTrap();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { visible } = this.state;
    if (prevState.visible !== visible && visible) {
      this.activateFocusTrap();
    }
  }

  /**
   * Hide the bar.
   */
  hidePermissionBar = () => {
    this.setState({ visible: false }, () => {
      if (this.focusTrapInstance) {
        this.focusTrapInstance.deactivate();
      }
    });
  };

  /**
   * Handle refusal.
   * Set permissionToSave status to false.
   */
  handleRefuse = () => {
    localStorage.setItem('permissionToSave', 'false');
    this.hidePermissionBar();
  };

  /**
   * Handle acceptance.
   * Set permissionToSave status to true.
   */
  handleAccept = () => {
    localStorage.setItem('permissionToSave', 'true');
    this.hidePermissionBar();
  };

  activateFocusTrap = () => {
    this.focusTrapInstance = focusTrap.createFocusTrap('.cookie-bar', {
      allowOutsideClick: true,
      clickOutsideDeactivates: true,
    });
    this.focusTrapInstance.activate();
  };

  render() {
    const { visible } = this.state;

    return (
      <Fragment>
        {visible && (
          <StyledWrapper className="cookie-bar">
            <StyledParagraph>
              Lansingerland slaat gegevens op in de browser om deze applicatie gebruiksvriendelijker
              te maken. Meer informatie vindt u onder de Info-knop.
            </StyledParagraph>
            <StyledControls>
              <StyledLink onClick={() => this.handleRefuse()}>Weigeren</StyledLink>
              <StyledButton onClick={() => this.handleAccept()}>Accepteren</StyledButton>
            </StyledControls>
          </StyledWrapper>
        )}
      </Fragment>
    );
  }
}

export default PermissionBar;
