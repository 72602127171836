const colors = {
  white: '#ffffff',
  darkGray: '#707070',
  gray: '#949494',
  lightGray: '#fafafa',
  red: '#e00614',
  lightRed: '#f5bcbc',
  green: '#0d8228',
  black: '#000',
};

export default colors;
