import {
  CLEAR_FORM_STORE_ALL,
  CLEAR_FORM_STORE_SOME,
  GET_FORM_STORE,
  SAVE_GEO,
  UPDATE_FORM_STORE,
} from '../actions/types';
import form from '../store/initialStates/form';

const initialState = form;

export default function formReducer(state = initialState, action) {
  switch (action.type) {
    case GET_FORM_STORE:
      return {
        ...state,
      };
    case SAVE_GEO:
      return {
        ...state,
        location: action.payload.name,
      };
    case UPDATE_FORM_STORE:
      return {
        ...state,
        ...action.newAttributes,
        errors: {
          ...state.errors,
          ...action.errors,
        },
      };
    case CLEAR_FORM_STORE_ALL:
      return {
        ...initialState,
      };
    case CLEAR_FORM_STORE_SOME:
      return {
        ...initialState,
        address: state.address,
        city: state.city,
        email: state.email,
        name: state.name,
        phoneNumber: state.phoneNumber,
        statusUpdates: state.statusUpdates,
      };
    default:
      return state;
  }
}
