const lansingerland = {
  COMMUNE_NAME: 'lansingerland',
  LOGO: {
    URL: 'https://www.lansingerland.nl',
  },
  MAP: {
    LAT: 51.98161005,
    LNG: 4.493433750614987,
  },
};

const yard = {
  COMMUNE_NAME: 'utrecht',
  LOGO: {
    URL: 'https://yard.nl',
  },
  MAP: {
    LAT: 52.08254,
    LNG: 5.14211,
  },
};

const config = process.env.REACT_APP_THEME === 'lansingerland' ? lansingerland : yard;

/**
 * Add common config values here
 */
export default {
  VERSION: process.env.REACT_APP_VERSION,
  ...config,
};
