import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import locationIcon from '../../img/location.svg';

/**
 * Styling of the component.
 *
 * @type {Object}
 */
const StyledInputGroup = styled.div`
  position: relative;
  width: 100%;
`;

const StyledInput = styled.input`
  width: ${props => props.theme.input.width};
  height: ${props => props.theme.input.height};
  padding: ${props => props.theme.input.padding} calc(${props => props.theme.input.padding} * 1.5);
  font-family: ${props => props.theme.input.fontFamily};
  font-size: ${props => props.theme.input.fontSize};
  font-weight: ${props => props.theme.input.fontWeight};
  outline: none;
  border-radius: ${props => props.theme.input.borderRadius};
  border: 1px solid
    ${props => (props.error ? props.theme.input.borderError : props.theme.input.borderColor)};
  background: ${props => props.theme.input.background};
  transition: all 0.3s ease;
  color: #000;

  &:focus + label,
  &:not(:placeholder-shown) + label {
    transform: translate(0.3rem, -1.7rem) scale(0.8);
  }

  &:focus {
    box-shadow: 0 0 4px ${props => props.theme.input.focus};
    border: 1px solid ${props => props.theme.input.focus};
  }
`;

const StyledLabel = styled.label`
  position: absolute;
  top: 13px;
  left: 14px;
  display: inline-block;
  padding: 0rem 0.4rem;
  border-radius: 15px;
  background-color: #fff;
  color: #707070;
  font-size: 16px;
  pointer-events: none;
  transition: transform 0.2s ease;
  transform-origin: left bottom;
`;

/* eslint-disable */
const StyledIcon = styled.span`
  position: absolute;
  background-size: 100%;
  right: 23px;
  top: 13px;
  max-width: 15px;
  max-height: 25px;
  height: 100%;
  width: 100%;

  ${icon =>
    (icon =
      'location' &&
      css`
        background-image: url(${locationIcon});
      `)}
`;
/* eslint-enable */

const StyledFeedback = styled.div`
  margin: ${props => props.theme.errorMessageFeedback.margin};
  font-size: ${props => props.theme.errorMessageFeedback.fontSize};
  color: ${props => props.theme.errorMessageFeedback.color};
`;

const StyledRequired = styled.span`
  color: ${props => props.theme.requiredAsteriks.color};
  position: absolute;
  right: -10px;
  top: -10px;
`;

class TextInput extends PureComponent {
  render() {
    const {
      error,
      forceAcceptEmpty,
      icon,
      name,
      onChange,
      placeholder,
      type,
      value,
      autoComplete,
    } = this.props;

    return (
      <StyledInputGroup>
        <StyledInput
          id={name}
          error={error}
          name={name}
          onChange={onChange}
          placeholder=" "
          type={type}
          value={value}
          autoComplete={autoComplete}
        />
        <StyledLabel htmlFor={name}>{placeholder}</StyledLabel>
        {!forceAcceptEmpty && <StyledRequired>*</StyledRequired>}
        {icon && (
          <StyledIcon>
            <i className={icon} />
          </StyledIcon>
        )}
        {error && <StyledFeedback role="alert">{error}</StyledFeedback>}
      </StyledInputGroup>
    );
  }
}

TextInput.propTypes = {
  error: PropTypes.string,
  forceAcceptEmpty: PropTypes.bool,
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

TextInput.defaultProps = {
  error: '',
  forceAcceptEmpty: false,
  icon: '',
  placeholder: null,
  autocomplete: null,
};

export default TextInput;
