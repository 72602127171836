import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import ViewWrapper from '../components/ViewWrapper';
import Heading from '../components/common/Heading';
import ButtonSolid from '../components/common/ButtonSolid';
import InputWrapper from '../components/InputWrapper';

/**
 * Styling of the component.
 *
 * @type {Object}
 */
const StyledPhoneNumber = styled.a`
  color: ${props => props.theme.stepDonePhoneNumber.color};
  font-size: ${props => props.theme.stepDonePhoneNumber.fontSize};
  margin-bottom: ${props => props.theme.stepDonePhoneNumber.marginBottom};
  text-decoration: none;
`;

class DoneView extends Component {
  /**
   * Types of props.
   *
   * @type {Object}
   */
  static propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
  };

  constructor(props) {
    super(props);

    this.onButtonClick = this.onButtonClick.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   * Handle button click
   * @param {Object} event
   */
  onButtonClick() {
    const { history } = this.props;
    history.push('/melding-maken');
  }

  render() {
    return (
      <ViewWrapper center>
        <Heading center size={2} title="Hartelijk dank voor het doorgeven!" />
        <p>
          Wij verwerken uw gegevens zo snel mogelijk. Heeft u nog vragen? Dan kunt u ons op
          werkdagen tussen 8.30 en 17.00 uur bellen via telefoonnummer:
        </p>
        <InputWrapper>
          <StyledPhoneNumber href="tel:+31108004000">(010) 800 40 00</StyledPhoneNumber>
        </InputWrapper>
        <ButtonSolid title="Nieuwe melding" icon="plus" onClick={this.onButtonClick} />
      </ViewWrapper>
    );
  }
}

export default withRouter(DoneView);
