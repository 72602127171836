import React, { Component } from 'react';
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import config from '../../config';
import GeoSelectAxios from './GeoSelectAxios';

/**
 * Styling of the component.
 *
 * @type {Object}
 */
const Container = styled.div`
  position: relative;
  z-index: 500;
`;

const onAttention = '&:hover, &:focus';
const Input = styled.input`
  background: ${props => props.theme.select.background};
  border: ${props => props.theme.select.border};
  border-color: ${props => (props.showError ? props.theme.select.borderError : '')};
  border-radius: ${props => props.theme.select.borderRadius};
  display: inline-block;
  font-size: ${props => props.theme.select.fontSize};
  height: ${props => props.theme.select.height};
  line-height: ${props => props.theme.select.lineHeight};
  max-width: ${props => props.theme.select.maxWidth};
  padding: ${props => props.theme.select.padding};
  transition: box-shadow 0.1s ease, width 0.1s ease;
  white-space: normal;
  width: ${props => props.theme.select.width};
  word-wrap: break-word;

  ::-ms-clear {
    display: none;
  }

  ${onAttention}: {
    border-color: #96c8da;
    box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
  };

  ${({ isOpen }) =>
    isOpen &&
    `
    border-radius: 25px;
    border-bottom-left-radius : 0;
    border-bottom-right-radius: 0;
    [onAttention]: {
      boxShadow: none,
    },
  `}
`;

const BaseMenu = styled.ul`
  background-color: white;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
  margin-top: 0;
  max-height: 20rem;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  transition: opacity 0.1s ease;
  width: 100%;
  z-index: 10;

  &:focus-visible {
    box-shadow: 0 0 4px ${props => props.theme.input.focus};
    outline: 2px solid ${props => props.theme.input.focus};
  }

  /* Remove default focus styles for mouse users if :focus-visible is supported */
  &:focus:not(:focus-visible) {
    outline: none;
  }

  ${({ isOpen }) =>
    !isOpen &&
    `
    display: none;
  `}
`;

const ControllerButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
`;

const Item = styled.li`
  border: none;
  box-shadow: none;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  height: auto;
  padding: 0.8rem 1.1rem;
  position: relative;
  text-align: left;
  text-transform: none;
  white-space: normal;
  word-wrap: normal;

  &[aria-selected='true'] {
    background-color: ${props => props.theme.combobox.backgroundHover};
  }
`;

function ArrowIcon({ isOpen }) {
  return (
    <svg
      viewBox="0 0 20 20"
      preserveAspectRatio="none"
      height={16}
      width={16}
      fill="transparent"
      stroke="#707070"
      strokeWidth="1.1px"
      transform={isOpen ? 'rotate(180)' : undefined}
    >
      <path d="M1,6 L10,15 L19,6" />
    </svg>
  );
}

function XIcon() {
  return (
    <svg
      viewBox="0 0 20 20"
      preserveAspectRatio="none"
      height={12}
      width={12}
      fill="transparent"
      stroke="#707070"
      strokeWidth="1.1px"
    >
      <path d="M1,1 L19,19" />
      <path d="M19,1 L1,19" />
    </svg>
  );
}

const StyledFeedback = styled.div`
  margin: ${props => props.theme.errorMessageFeedback.margin};
  font-size: ${props => props.theme.errorMessageFeedback.fontSize};
  color: ${props => props.theme.errorMessageFeedback.color};
`;

const StyledRequired = styled.span`
  color: ${props => props.theme.requiredAsteriks.color};
  position: absolute;
  right: -10px;
  top: -10px;
`;

const baseEndpoint = `https://api.pdok.nl/bzk/locatieserver/search/v3_1/suggest?wt=json&fq=type:adres&fq=gemeentenaam:${config.COMMUNE_NAME}`;

class GeoSelect extends Component {
  constructor(props) {
    super(props);

    const { value } = this.props;

    this.state = {
      input: value,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value) {
      this.setState({ input: nextProps.value });
    }
  }

  /**
   * Update the state when the input value is changing.
   * @param {Object} event
   */
  handleInputChange = e => {
    const { onChange } = this.props;
    const { value } = e.target;

    this.setState({ input: value });

    onChange(e);
  };

  /**
   * Update the state when the input value is selected by click.
   * @param {Object} event
   */
  handleChange = item => {
    const { name, onChange } = this.props;

    if (item) {
      const data = {
        target: {
          name,
          value: item.weergavenaam,
          id: item.id,
        },
      };
      onChange(data);
      this.setState({ input: item.weergavenaam });
    } else {
      const data = {
        target: {
          name,
          value: null,
        },
      };
      onChange(data);
      this.setState({ input: null });
    }
  };

  render() {
    const { error, forceAcceptEmpty, name, placeholder, onOptionClick, ariaLabel } = this.props;
    const { input } = this.state;

    return (
      <Container>
        <Downshift
          itemToString={item => (item ? input : '')}
          selectedItem={input}
          onChange={this.handleChange}
          onSelect={onOptionClick}
        >
          {({
            inputValue,
            getInputProps,
            getMenuProps,
            getItemProps,
            getToggleButtonProps,
            selectedItem,
            highlightedIndex,
            isOpen,
            clearSelection,
          }) => (
            <div>
              <div>
                <Input
                  {...getInputProps({
                    isOpen,
                    name,
                    placeholder,
                    'aria-label': ariaLabel,
                    'aria-labelledby': undefined,
                  })}
                />
                {selectedItem ? (
                  <ControllerButton onClick={clearSelection} aria-label="Verwijder selectie">
                    <XIcon />
                  </ControllerButton>
                ) : (
                  <ControllerButton {...getToggleButtonProps()}>
                    <ArrowIcon isOpen={isOpen} />
                  </ControllerButton>
                )}
              </div>
              <BaseMenu {...getMenuProps({ isOpen })}>
                {(() => {
                  if (!isOpen) {
                    return null;
                  }

                  if (!inputValue) {
                    return (
                      <Item disabled>Type de naam van een straat en kies de juiste straat</Item>
                    );
                  }

                  return (
                    <GeoSelectAxios url={baseEndpoint} params={{ q: inputValue }}>
                      {({ loading, error, data: { docs = [] } = {} }) => {
                        if (loading) {
                          return <Item disabled>Laden...</Item>;
                        }

                        if (error) {
                          return <Item disabled>Error! ${error}</Item>;
                        }

                        if (!docs.length) {
                          return <Item disabled>Geen adressen gevonden</Item>;
                        }

                        return docs.map((item, index) => (
                          <Item
                            key={item.id}
                            {...getItemProps({
                              item,
                              index,
                              isActive: highlightedIndex === index,
                              isSelected: selectedItem === item,
                            })}
                          >
                            {item.weergavenaam}
                          </Item>
                        ));
                      }}
                    </GeoSelectAxios>
                  );
                })()}
              </BaseMenu>
            </div>
          )}
        </Downshift>
        {!forceAcceptEmpty && <StyledRequired>*</StyledRequired>}
        {error && <StyledFeedback>{error}</StyledFeedback>}
      </Container>
    );
  }
}

GeoSelect.propTypes = {
  error: PropTypes.string,
  forceAcceptEmpty: PropTypes.bool,
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onOptionClick: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  ariaLabel: PropTypes.string,
};

GeoSelect.defaultProps = {
  error: '',
  forceAcceptEmpty: false,
  icon: '',
  placeholder: null,
  value: null,
  ariaLabel: '',
  onOptionClick: () => {},
};

export default GeoSelect;
