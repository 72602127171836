import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import validateStepThree from '../../utils/validation/step-three';
import { updateFormStore } from '../../actions/formActions';
import ViewWrapper from '../../components/ViewWrapper';
import Navigation from '../../components/Navigation';
import Heading from '../../components/common/Heading';
import InputWrapper from '../../components/InputWrapper';
import TextInput from '../../components/common/TextInput';

/**
 * Styling of the component.
 *
 * @type {Object}
 */
const OuterContainer = styled.form`
  height: 100%;
  max-width: 100%;
`;

const StyledInputDescription = styled.div`
  color: ${props => props.theme.inputDescription.color};
  font-size: 14px;
  margin: ${props => props.theme.inputDescription.margin};
`;

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const StyledListItem = styled.li`
  font-size: 16px;
  margin-bottom: 10px;
`;

const StyledFeedback = styled.div`
  margin: ${props => props.theme.errorMessageFeedback.margin};
  font-size: ${props => props.theme.errorMessageFeedback.fontSize};
  color: ${props => props.theme.errorMessageFeedback.color};
`;

class StepThree extends Component {
  /**
   * Initialize state.
   *
   * @type {Object}
   */
  constructor(props) {
    super(props);

    const { form } = this.props;

    this.state = {
      address: form.address,
      city: form.city,
      email: form.email,
      errors: form.errors,
      name: form.name,
      phoneNumber: form.phoneNumber,
      statusUpdates: form.statusUpdates,
      stepValidated: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateStep = this.validateStep.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   * Update the local form state on every change.
   * @param {Object} event
   */
  handleChange = e => {
    const { name, value } = e.target;

    this.setState({ [name]: value }, this.validateStep);
  };

  /**
   * Validate the current step, and decide wether or not the user
   * may continue to the next step
   *
   * @return {Void}
   */
  validateStep = () => {
    const { address, city, email, name, phoneNumber, statusUpdates } = this.state;
    const { errors, isValid } = validateStepThree({
      address,
      city,
      email,
      name,
      phoneNumber,
      statusUpdates,
    });

    this.setState({ errors });

    if (isValid) {
      this.setState({ stepValidated: true });
    }
  };

  /**
   * Update the Redux state and update the local state with the same props
   * this ensures that both contain the same data.
   * @param {Object} event
   */
  handleSubmit = e => {
    e.preventDefault();
    const { props } = this;
    const { stepValidated } = this.state;

    this.validateStep();

    if (stepValidated) {
      props.updateFormStore(this.state);
    }
  };

  /**
   * Render the location view.
   *
   * @return {Object}
   */
  render() {
    const {
      address,
      city,
      email,
      errors,
      name,
      phoneNumber,
      statusUpdates,
      stepValidated,
    } = this.state;

    return (
      <OuterContainer key="form-step-three" onSubmit={this.handleSubmit}>
        <ViewWrapper>
          <Heading center size={2} title="Mijn gegevens" />
          <InputWrapper>
            <TextInput
              error={errors.name}
              name="name"
              onChange={this.handleChange}
              placeholder="Naam"
              type="text"
              value={name}
              autoComplete="name"
            />
          </InputWrapper>
          <InputWrapper>
            <TextInput
              error={errors.address}
              forceAcceptEmpty
              name="address"
              onChange={this.handleChange}
              placeholder="Adres"
              type="text"
              value={address}
              autoComplete="address-line1"
            />
          </InputWrapper>
          <InputWrapper>
            <TextInput
              error={errors.city}
              forceAcceptEmpty
              name="city"
              onChange={this.handleChange}
              placeholder="Woonplaats"
              type="text"
              value={city}
              autoComplete="address-level2"
            />
          </InputWrapper>
          <InputWrapper>
            <TextInput
              error={errors.phoneNumber}
              name="phoneNumber"
              onChange={this.handleChange}
              placeholder="Telefoonnummer"
              type="text"
              value={phoneNumber}
              autoComplete="tel-national"
            />
            <StyledInputDescription>Geschreven als 0612345678</StyledInputDescription>
          </InputWrapper>
          <small>Telefoonnummer waarop we u kunnen bereiken bij vragen over de melding</small>
          <Heading center size={2} title="Wilt u op de hoogte blijven?" />
          <StyledList>
            <StyledListItem>
              <label htmlFor="a">
                <input
                  name="statusUpdates"
                  onChange={this.handleChange}
                  type="radio"
                  checked={statusUpdates === 'Ja'}
                  value="Ja"
                  id="a"
                />
                {` `}
                Ja
              </label>
              {statusUpdates === 'Ja' && (
                <InputWrapper>
                  <TextInput
                    error={errors.email}
                    name="email"
                    onChange={this.handleChange}
                    placeholder="E-mailadres"
                    type="text"
                    value={email}
                    autoComplete="email"
                  />
                </InputWrapper>
              )}
            </StyledListItem>
            <StyledListItem>
              <label htmlFor="c">
                <input
                  name="statusUpdates"
                  onChange={this.handleChange}
                  type="radio"
                  checked={statusUpdates === 'Nee'}
                  value="Nee"
                  id="c"
                />
                {` `}
                Nee
              </label>
            </StyledListItem>
          </StyledList>
          <StyledFeedback>* verplicht veld</StyledFeedback>
          <Navigation stepValidated={stepValidated} />
        </ViewWrapper>
      </OuterContainer>
    );
  }
}

StepThree.propTypes = {
  form: PropTypes.shape({
    category: PropTypes.string,
    description: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    email: PropTypes.string,
    errors: PropTypes.instanceOf(Object),
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    statusUpdates: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => ({
  form: state.form,
});

const mapDispatchToProps = {
  updateFormStore,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepThree);
