import axios from 'axios';

import { CLEAR_GEO_STORE, SAVE_GEO } from './types';

const API_URL = 'https://api.pdok.nl/bzk';

/**
 * Find Geo coordinates for a location based on input from the dropdown.
 * So that we can place a marker on the map.
 *
 * @param objectId
 * @returns {Object}
 */
export const lookupGeoCoordinates = objectId => dispatch =>
  axios
    .get(`${API_URL}/locatieserver/search/v3_1/lookup?id=${objectId}`)
    .then(res => {
      const data = res.data.response.docs[0];
      const latlng = data.centroide_ll;
      const name = data.weergavenaam;

      // splice POINT() of our data and reverse.
      const coordinates = latlng
        .substr(6)
        .slice(0, -1)
        .split(' ')
        .reverse();

      dispatch({
        type: SAVE_GEO,
        payload: {
          lat: parseFloat(coordinates[0]),
          lng: parseFloat(coordinates[1]),
          name,
        },
      });
    })
    .catch(error => {
      throw error;
    });

/**
 * Find Place information based on a coordinates (Lat/Lon).
 * So that we can populate the dropdown field with a name.
 *
 * This is called reverse geocoding.
 *
 * @param objectId
 * @returns {Object}
 */
export const lookupPlaceByCoordinates = (lat, lng) => dispatch =>
  axios
    .get(`${API_URL}/locatieserver/search/v3_1/reverse?lat=${lat}&lon=${lng}&rows=1`)
    .then(res => {
      const data = res.data.response.docs[0];
      const name = data.weergavenaam;

      dispatch({
        type: SAVE_GEO,
        payload: {
          lat,
          lng,
          name,
        },
      });
    })
    .catch(error => {
      throw error;
    });

export function clearGeoStore() {
  return {
    type: CLEAR_GEO_STORE,
  };
}
