import { combineReducers } from 'redux';

import categoriesReducer from './categoriesReducer';
import formReducer from './formReducer';
import geoReducer from './geoReducer';

export default combineReducers({
  categories: categoriesReducer,
  form: formReducer,
  geo: geoReducer,
});
