import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { WithWizard } from 'react-albus';
import styled from 'styled-components';

import Button from './common/Button';

/**
 * Styling of the component.
 *
 * @type {Object}
 */
const StyledNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.theme.navigation.padding};
`;

const StyledNavLeft = styled.div`
  text-align: left;
`;

const StyledNavRight = styled.div`
  text-align: right;
`;

class Navigation extends Component {
  /**
   * Initialize state.
   *
   * @type {Object}
   */
  constructor(props) {
    super(props);

    this.state = {
      stepValidated: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.stepValidated !== prevState.stepValidated) {
      return { stepValidated: nextProps.stepValidated };
    }
    return null;
  }

  /**
   * Handle click.
   * The navigation does not call a validate function,
   * it checks whether a stepValidated prop was passed down from the parent.
   * @param {next} callback - Navigate to the next step.
   */
  handleClick(callback) {
    const { stepValidated } = this.state;

    if (stepValidated) {
      return callback();
    }

    return null;
  }

  render() {
    const { finalStep, isLoading, onClick } = this.props;

    return (
      <WithWizard
        render={({ next, previous, step, steps }) => (
          <StyledNavigation>
            <StyledNavLeft>
              {steps.indexOf(step) > 0 && <Button title="Vorige" onClick={previous} left gray />}
            </StyledNavLeft>
            {!finalStep && (
              <StyledNavRight>
                {steps.indexOf(step) < steps.length - 1 && (
                  <Button type="submit" title="Volgende" onClick={() => this.handleClick(next)} />
                )}
              </StyledNavRight>
            )}
            {finalStep && (
              <StyledNavRight>
                {!isLoading && <Button title="Verstuur uw melding" onClick={onClick} />}
                {isLoading && <p>Aan het versturen...</p>}
              </StyledNavRight>
            )}
          </StyledNavigation>
        )}
      />
    );
  }
}

Navigation.propTypes = {
  finalStep: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
};

Navigation.defaultProps = {
  finalStep: false,
  isLoading: false,
  onClick: null,
};

export default Navigation;
