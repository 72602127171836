export default {
  address: '',
  category: 'default',
  city: '',
  description: '',
  email: '',
  location: '',
  locationDescription: '',
  locationThroughMap: false,
  name: '',
  phoneNumber: '',
  statusUpdates: 'Ja',
  uploads: [],
  errors: {
    address: null,
    category: null,
    city: null,
    description: null,
    email: null,
    location: null,
    locationDescription: null,
    name: null,
    phoneNumber: null,
    statusUpdates: null,
  },
};
