import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

/**
 * Styling of the component.
 *
 * @type {Object}
 */
const StyledTextAreaGroup = styled.div`
  position: relative;
  width: 100%;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: ${props => props.theme.textarea.padding};
  border-radius: ${props => props.theme.textarea.borderRadius};
  transition: all 0.3s ease;
  border: 1px solid
    ${props => (props.error ? props.theme.textarea.borderError : props.theme.textarea.border)};
  background: ${props => props.theme.textarea.background};
  color: ${props => props.theme.textarea.color};
  font-family: ${props => props.theme.textarea.fontFamily};
  font-size: ${props => props.theme.textarea.fontSize};
  font-weight: ${props => props.theme.textarea.fontWeight};
  line-height: 23px;

  &:focus + label,
  &:not(:placeholder-shown) + label {
    transform: translate(0.3rem, -1.7rem) scale(0.8);
  }

  &:focus {
    box-shadow: 0 0 4px ${props => props.theme.input.focus};
    border: 1px solid ${props => props.theme.input.focus};
  }
`;

const StyledLabel = styled.label`
  display: inline-block;
  position: absolute;
  top: 12px;
  left: 14px;
  transition: transform 0.2s ease;
  pointer-events: none;
  color: #707070;
  background-color: #fff;
  border-radius: 15px;
  font-size: 16px;
  padding: 0rem 0.4rem;
  transform-origin: left bottom;
`;

const StyledFeedback = styled.div`
  margin: ${props => props.theme.errorMessageFeedback.margin};
  font-size: ${props => props.theme.errorMessageFeedback.fontSize};
  color: ${props => props.theme.errorMessageFeedback.color};
`;

const StyledRequired = styled.span`
  color: ${props => props.theme.requiredAsteriks.color};
  position: absolute;
  right: -10px;
  top: -10px;
`;

const TextArea = ({ error, forceAcceptEmpty, name, onChange, placeholder, rows, value }) => (
  <StyledTextAreaGroup>
    <StyledTextArea
      id={name}
      error={error}
      name={name}
      onChange={onChange}
      placeholder=" "
      rows={rows}
      value={value}
    />
    <StyledLabel htmlFor={name}>{placeholder}</StyledLabel>
    {!forceAcceptEmpty && <StyledRequired>*</StyledRequired>}
    {error && <StyledFeedback role="alert">{error}</StyledFeedback>}
  </StyledTextAreaGroup>
);

TextArea.propTypes = {
  error: PropTypes.string,
  forceAcceptEmpty: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.string,
  value: PropTypes.string.isRequired,
};

TextArea.defaultProps = {
  error: '',
  forceAcceptEmpty: false,
  placeholder: null,
  rows: null,
};

export default TextArea;
